.tk-calendar
	max-width: 100%

	&__header
		display: flex
		align-items: center
		justify-content: space-between
		text-align: center

	&__weekdays
		display: flex
		align-items: center

	&__weekday
		text-align: center

	&__days
		display: flex
		flex-wrap: wrap

	&__day
		display: flex
		align-items: center
		justify-content: center
