$main-black: #0b0c0c
$main-brand: #1c55e2
$main-career: #153bc0
$agona-blue: #002dff
$bg-grey: #f2f4f7
$admin-grey: #000b26
$vacancy-grey: #ebeef5
$admin-blue: #5780ea
$admin-red: #ff4545
$error: #ff5530
$light-100: #f3f3f3

$c-light-grey: #f9f9fa

$c-primary-100: #4f80ff
$c-primary-300: #426BD6

$c-surface-on-primary: #fffffe

$c-primary-surface: #4f80ff
$c-primary-surface-400: rgba($c-primary-surface, 0.32)

$c-icons-100: #333c51

$c-red-100: #fc1414b7
$c-red-72: rgba($c-red-100, 0.72)

$c-base: #000b26
$c-base-88: rgba($c-base, 0.88)
$c-base-80: rgba($c-base, 0.80)
$c-base-72: rgba($c-base, 0.72)
$c-base-64: rgba($c-base, 0.64)
$c-base-40: rgba($c-base, 0.4)
$c-base-16: rgba($c-base, 0.16)

$white-90: rgba(white, 90%)
$white-80: rgba(white, 80%)
$white-70: rgba(white, 70%)
$white-60: rgba(white, 60%)
$white-50: rgba(white, 50%)
$white-40: rgba(white, 40%)
$white-30: rgba(white, 30%)
$white-20: rgba(white, 20%)
$white-10: rgba(white, 10%)
$white-5: rgba(white, 5%)
$white-3: rgba(white, 3%)

$gradient-silver: linear-gradient(91.15deg, #FFFFFF 0%, rgba(white, 25%) 171.06%)
