@import "mixins"

body
	font-family: Grandis, sans-serif
	font-style: normal
	font-weight: normal
	font-variant-ligatures: none
	text-rendering: optimizeLegibility
	font-smoothing: antialiased
	font-smooth: always
	-webkit-text-size-adjust: 100%
	background: $main-black
	color: white
	overflow-x: hidden

hr
	margin: 0
	border: none

.ng-submitted
		input.ng-invalid, textarea.ng-invalid
			box-shadow: 0 0 0 1px $error

.tk-button
	padding: 10px 23px
	border: 1px solid
	border-radius: 16px
	font-size: 16px
	line-height: 24px
	font-weight: 500
	transition: all .2s linear

	&--theme
		//agona-primary
		&-primary
			color: white
			border-color: white

			&:hover
				color: white
				background: $main-brand

			&:active
				background: rgba($main-brand, 0.9)

		//agona-reversed
		&-secondary
			color: white
			background: $main-brand
			border-color: $main-brand

			&:hover
				color: $main-brand
				background: white

			&:active
				background: transparent
				border-color: rgba($main-brand, 0.9)

		//white
		&-ghost
			color: white
			border-color: white
			background-color: transparent

			&:hover
				color: black
				background: white

			&:active
				background: rgba(white, 0.9)

		//black
		&-raised
			color: $main-black
			border-color: $main-black

			&:hover
				color: white
				background: $main-black

			&:active
				background: rgba($main-black, 0.9)

		//agona
		&-inline
			@extend .tk-button--theme-primary
			border-color: $main-brand
			color: $main-brand

.cdk-overlay-backdrop
	&--blue
		background-color: $main-brand

	&--agona
		background-color: $agona-blue

	&--black
		background-color: $main-black

	.cdk-overlay-container
		&--slideUp
			animation: slideUp 0.5s forwards 0s ease-in

		&--slideDown
			transform: translateY(0%)
			animation: slideDown 0.5s forwards 0s ease-in

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
	-webkit-appearance: none
	margin: 0

input[type=number]
	-moz-appearance: textfield

.cdk-global-scrollblock
	position: unset
	touch-action: none
	-webkit-overflow-scrolling: none
	overflow: hidden
	overscroll-behavior: none

	body
		height: 100vh
		touch-action: none
		-webkit-overflow-scrolling: none
		overflow: hidden
		overscroll-behavior: none

.toast-success
	margin-bottom: 0 !important
	padding: 24px !important
	background: $main-black !important
	box-shadow: none !important
	border-radius: 16px !important
	border: 1px solid white
	@include font-20-26

.toast-error
	margin-bottom: 0 !important
	padding: 24px !important
	box-shadow: none !important
	border-radius: 16px !important
	@include font-20-26
	background-image: none !important

.toast-close-button
	position: absolute !important
	top: 24px
	right: 16px
	width: 26px !important
	height: 26px !important
	background-image: none !important

	&:hover
		opacity: 1 !important

	span
		color: white !important
		opacity: 0.2

