.tk-radio-group
	display: flex

	&--vertical
		flex-direction: column

	&--horizontal
		flex-wrap: wrap
		align-items: flex-start

.tk-radio-button
	display: inline-flex
	align-items: center
	justify-content: space-between
	-webkit-tap-highlight-color: transparent
	outline: 0
	cursor: pointer

	&__wrapper
		display: flex
		align-items: center

		&--reverse
			flex-flow: row-reverse

	&__circle
		position: relative
		font-size: 0

	&__children
		flex: 1 0 0%

	&__suffix
		flex: 0 0 auto

	&--disabled
		pointer-events: none
