@import 'colors'
@import 'ngx-toastr/toastr'
@import 'highlight.js/styles/github-dark.css'

$container-size: 1336px
$large-container-size: 1376px

@mixin font-12-20
	font-size: 12px
	line-height: 20px

@mixin font-13-20
	font-size: 13px
	line-height: 20px

@mixin font-14-20
	font-size: 14px
	line-height: 20px

@mixin font-15-18
	font-size: 15px
	line-height: 18px

@mixin font-16-20
	font-size: 16px
	line-height: 20px

@mixin font-16-22
	font-size: 16px
	line-height: 22px

@mixin font-16-24
	font-size: 16px
	line-height: 24px

@mixin font-16-24-spacing
	font-size: 16px
	line-height: 24px
	letter-spacing: -0.25px

@mixin font-18-24
	font-size: 18px
	line-height: 24px

@mixin font-18-28
	font-size: 18px
	line-height: 28px

@mixin font-20-24
	font-size: 20px
	line-height: 24px

@mixin font-20-28
	font-size: 20px
	line-height: 28px

@mixin font-20-26
	font-size: 20px
	line-height: 26px

@mixin font-20-32
	font-size: 20px
	line-height: 32px

@mixin font-22-28
	font-size: 22px
	line-height: 28px
	letter-spacing: -0.22px

@mixin font-24-28
	font-size: 24px
	line-height: 28px

@mixin font-24-32
	font-size: 24px
	line-height: 32px

@mixin font-24-36
	font-size: 24px
	line-height: 36px

@mixin font-28-24
	font-size: 28px
	line-height: 24px

@mixin font-28-32
	font-size: 28px
	line-height: 32px

@mixin font-28-36
	font-size: 28px
	line-height: 36px

@mixin font-28-38
	font-size: 28px
	line-height: 38px

@mixin font-28-40
	font-size: 28px
	line-height: 40px

@mixin font-32-36
	font-size: 32px
	line-height: 36px

@mixin font-32-40
	font-size: 32px
	line-height: 40px

@mixin font-32-44
	font-size: 32px
	line-height: 44px

@mixin font-32-48
	font-size: 32px
	line-height: 48px

@mixin font-36-40
	font-size: 36px
	line-height: 40px

@mixin font-38-42
	font-size: 38px
	line-height: 42px

@mixin font-36-46
	font-size: 36px
	line-height: 46px

@mixin font-40-44
	font-size: 40px
	line-height: 44px
	letter-spacing: -1.3px

@mixin font-40-48
	font-size: 40px
	line-height: 48px

@mixin font-40-52
	font-size: 40px
	line-height: 52px

@mixin font-46-56
	font-size: 46px
	line-height: 56px

@mixin font-48-53
	font-size: 48px
	line-height: 53px

@mixin font-48-54
	font-size: 48px
	line-height: 54px

@mixin font-48-56
	font-size: 48px
	line-height: 56px

@mixin font-48-60
	font-size: 48px
	line-height: 60px

@mixin font-48-64
	font-size: 48px
	line-height: 64px

@mixin font-54-60
	font-size: 54px
	line-height: 60px

@mixin font-56-60
	font-size: 56px
	line-height: 60px

@mixin font-56-54
	font-size: 56px
	line-height: 54px

@mixin font-60-48
	font-size: 60px
	line-height: 48px

@mixin font-60-64
	font-size: 60px
	line-height: 64px

@mixin font-60-66
	font-size: 60px
	line-height: 66px

@mixin font-64-72
	font-size: 64px
	line-height: 72px

@mixin font-64-90
	font-size: 64px
	line-height: 90px

@mixin font-74-82
	font-size: 74px
	line-height: 82px

@mixin font-75-64
	font-size: 75px
	line-height: 64px

@mixin font-75-82
	font-size: 75px
	line-height: 82px

@mixin font-78-80
	font-size: 78px
	line-height: 80px

@mixin font-86-90
	font-size: 86px
	line-height: 90px

@mixin font-96-96
	font-size: 96px
	line-height: 96px

@mixin font-96-100
	font-size: 96px
	line-height: 96px

@mixin font-96-106
	font-size: 96px
	line-height: 106px

@mixin big-screens
	@media (min-width: 1440px)
		@content

@mixin desktop-small
	@media (max-width: 1439px)
		@content

@mixin tablet
	@media (max-width: 1023px)
		@content

@mixin mobile
	@media (max-width: 767px)
		@content

@mixin laptop
	@media (min-width: 1024px)
		@content

@mixin notMobile
	@media (min-width: 768px)
		@content

@mixin medium-size
	@media (min-width: 424px) and (max-width: 767px)
		@content

@mixin medium-mobile
	@media (max-width: 423px)
		@content

@mixin laptop-to-small
	@media (min-width: 1024px) and (max-width: 1439px)
		@content

@mixin tablet-to-laptop
	@media (min-width: 768px) and (max-width: 1023px)
		@content

@mixin tablet-to-small
	@media (min-width: 768px) and (max-width: 1439px)
		@content

@mixin link-card
	&:after
		position: absolute
		inset: 0
		content: ''
		z-index: 2

@mixin scroll-fix
	margin-left: calc(100vw - 100%)

@mixin flex-column($gap: 16px)
	display: flex
	flex-direction: column
	gap: $gap

@mixin content-wrapper
	width: 100%
	+flex-column(200px)

	@include tablet-to-laptop
		gap: 160px

	@include mobile
		gap: 80px

@mixin blue-gradient
	content: ''
	position: absolute
	-webkit-backface-visibility: hidden
	-moz-backface-visibility: hidden
	-webkit-transform: translate3d(0, 0, 0)
	-moz-transform: translate3d(0, 0, 0)
	filter: blur(233px)

@mixin blue-scrollbar
	-ms-overflow-style: none
	scrollbar-width: none

	&::-webkit-scrollbar
		height: 6px
		width: 12px

	&::-webkit-scrollbar-track
		background: white

	&::-webkit-scrollbar-thumb
		background-color: blue
		border-radius: 5px
		border: 3px solid transparent
