@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap')

@import '@angular/cdk/overlay-prebuilt.css'

@import '@technokratos/components-styles/reset'
@import '@technokratos/components-styles/components/index'

@import "mixins"
@import "main-styles"
@import "admin-typographics"

body
	font-family: 'Inter', sans-serif

.cdk-overlay-pane
	max-width: 100% !important

.cdk-overlay-backdrop
	background: rgba(black, 0.15)

.cookies-dialog-container
	display: flex
	justify-content: center

	cdk-dialog-container
		outline: none
		border: none !important
		background: none !important
		width: 100%

	@include medium-mobile
		width: 100%
		padding: 0

.ng-submitted tk-select.ng-invalid
	box-shadow: 0 0 0 1px $error

tk-form-field-info
	position: absolute

.toast-close-button
	top: 24px !important
	right: 24px !important

.toast-success
	background: rgba(black, 0.90) !important
