.tk-select
	display: block
	width: 100%

	&__arrow
		display: flex
		pointer-events: none

		&--rotate
			transform: rotate(180deg)

		tk-icon
			display: block

	&__suffix-wrapper
		display: flex
		align-items: center

	&__suffix
		pointer-events: none

		& > *
			pointer-events: auto

	&__value
		white-space: nowrap
		overflow: hidden
		text-overflow: ellipsis

.tk-select-trigger
	display: flex
	width: 100%
	text-align: left
	color: currentColor
	justify-content: space-between
