.tk-loader
	display: inline-block
	position: relative

	&--appearance-circle > div
		width: 100%
		height: 100%
		position: absolute
		border-radius: 50%

	&--appearance-dots
		display: flex
		align-items: center

		& > div
			border-radius: 50%
			flex: 0 0 auto
