.tk-input
	display: block
	width: 100%

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus input:-webkit-autofill, textarea:-webkit-autofill, textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus
	transition: background-color 5000s ease-in-out 0s

.tk-form-field
	width: 100%
	display: block
	text-align: left

	&__flex
		display: flex
		position: relative

	&__infix
		position: relative
		flex: 1 0 0%
		width: 0

	&__counter
		position: absolute
		top: 100%

	&__prefix, &__suffix
		pointer-events: none

		& > *
			pointer-events: auto

	input, textarea
		width: 100%

	textarea
		resize: none

	&--disabled
		pointer-events: none

	.tk-label
		position: absolute
		pointer-events: none
		white-space: nowrap
		overflow: hidden
		text-overflow: ellipsis

.tk-input-wrapper
	width: 100%
	display: block
	text-align: left

	&__flex
		display: flex

	&__infix
		position: relative
		flex: 1 0 0%
		width: 0

	&__prefix, &__suffix
		pointer-events: none

		& > *
			pointer-events: auto

	input, textarea
		width: 100%

	textarea
		resize: none

	&--disabled
		pointer-events: none
