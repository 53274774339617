.tk-panel
	display: flex
	flex-direction: column
	width: 100%
	-webkit-overflow-scrolling: touch
	overscroll-behavior: contain
	max-width: 100vw

	&:empty
		display: none
