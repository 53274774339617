@mixin tk-reset
	html, body, div, span, applet, object, iframe,
	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	a, abbr, acronym, address, big, cite, code,
	del, dfn, em, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, sup, tt, var,
	b, u, i, center,
	dl, dt, dd, ol, ul, li,
	fieldset, form, label, legend,
	table, caption, tbody, tfoot, thead, tr, th, td,
	article, aside, canvas, details, embed,
	figure, figcaption, footer, header, hgroup,
	menu, nav, output, ruby, section, summary,
	time, mark, audio, video, button
		margin: 0
		padding: 0
		border: 0
		font: inherit
		vertical-align: baseline

	/* HTML5 display-role reset for older browsers */
	article, aside, details, figcaption, figure,
	footer, header, hgroup, menu, nav, section
		display: block

	body
		line-height: 1

	ol, ul
		list-style: none

	blockquote, q
		quotes: none

	blockquote:before, blockquote:after,
	q:before, q:after
		content: ''
		content: none

	table
		border-collapse: collapse
		border-spacing: 0

	a
		text-decoration: none

	button, a
		cursor: pointer
		color: inherit

	button
		display: inline-block

	textarea, input, button, a
		font: inherit
		background: none
		outline: none
		border: none
		-webkit-appearance: none

	::-moz-focus-inner
		border: 0

	*
		box-sizing: border-box

	.cdk-visually-hidden
		border: 0
		clip: rect(0 0 0 0)
		height: 1px
		margin: -1px
		overflow: hidden
		padding: 0
		position: absolute
		width: 1px
		outline: 0
		appearance: none

