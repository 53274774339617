.tk-button-group
	display: flex
	align-items: center

.tk-button
	font-family: inherit
	display: inline-flex
	align-items: center
	justify-content: center
	text-align: center

	/* иначе веса не хватит */

	& &__icon
		&--first.tk-button__icon
			margin-left: 0

		&--last.tk-button__icon
			margin-right: 0

	&--stretch
		width: 100%
